import Alpine from 'alpinejs'

export default (function () {
  Alpine.data('slider', function (conf = {}) {
    return {
      instance: null,
      ready: false,
      enabled: null,
      transition: false,
      transitionToNext: false,
      transitionToPrev: false,
      activeIndex: 0,
      isActive(index) {
        return this.activeIndex === index
      },
      async load(controller) {
        const root = this.$root
        const slider = this.$refs['slider'] || this.$el
        const [Swiper, modules] =
          await Promise.all([
            import('swiper').then((mod) => mod.default),
            import('swiper/modules').then((mod) => [
              mod.Autoplay,
              mod.Navigation,
              mod.Pagination,
              mod.Thumbs,
            ]),
            import('swiper/css'),
            import('swiper/css/autoplay'),
            //import('swiper/css/pagination'),
          ])
        const [next, prev] = [
          this.$refs['next'],
          this.$refs['prev']
        ]
        const pagination = this.$refs['pagination']

        if (conf?.pagination) {
          conf.pagination.clickable = true
          conf.pagination.clickableClass = 'cursor-pointer'

          if (conf.pagination?.renderBullet) {
            conf.pagination.renderBullet = new Function('i', 'className', 'return ' + conf.pagination.renderBullet)
          }

          if (conf.pagination?.el) {
            conf.pagination.el = root.querySelector(conf.pagination.el)
          }

          if (pagination) {
            conf.pagination.el = pagination
          }
        }

        this.instance = new Swiper(slider, {
          modules,
          ...((next && prev) && {
            navigation: {
              nextEl: next,
              prevEl: prev,
              disabledClass: 'opacity-50'
            },
          }),
          ...(controller && {
            thumbs: {
              swiper: await controller,
            },
          }),
          ...conf,
          on: {
            afterInit: (instance) => {
              this.ready = true
              this.enabled = instance.enabled
            },
            enable: () => {
              this.enabled = true
            },
            disable: () => {
              this.enabled = false
            },
            slideChangeTransitionStart: () => {
              this.transition = true
            },
            slideChangeTransitionEnd: () => {
              this.transition = false
            },
            slideNextTransitionStart: () => {
              this.transitionToNext = true
            },
            slideNextTransitionEnd: () => {
              this.transitionToNext = false
            },
            slidePrevTransitionStart: () => {
              this.transitionToPrev = true
            },
            slidePrevTransitionEnd: () => {
              this.transitionToPrev = false
            },
            slideChange: (instance) => {
              this.activeIndex = instance.activeIndex
            },
          },
        })

        // bubble some events
        ;['slideChange'].map(ev => {
          this.instance.on(ev, () => {
            root.dispatchEvent(new CustomEvent(kebabize(ev), {
              bubbles: true,
              composed: true,
              cancelable: true,
              detail: this.instance,
            }))
          })
        })

        return this.instance
      },
    }
  })
})()


const kebabize = (str) => str.replace(/[A-Z]+(?![a-z])|[A-Z]/g, ($, ofs) => (ofs ? "-" : "") + $.toLowerCase())