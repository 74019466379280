import Alpine from 'alpinejs'

export default (() => {
  Alpine.data('form', function() {
    return {
      currentlyEditing: false,
      async init() {
        const form = this.$root.tagName === 'FORM' ? this.$root : this.$root.querySelector('form')
        const FetchManager = await import(/* webpackMode: "lazy-once" */'js-pimcore-formbuilder').then(mod => mod.FetchManager)

        new FetchManager(form, {
          onGeneralError: (generalErrorMessages) => {
            console.warn('Form error: ', generalErrorMessages)
            const errorEl = document.createElement('div')
            errorEl.classList.add('error-message', 'text-red-600', 'mx-6')
            errorEl.innerText = 'Momentan scheint es Probleme mit dem Server zu geben. Bitte versuchen Sie es erneut oder melden Sie sich bei dem Administrator.'
            form.after(errorEl)
          },
          onSuccess(messages, redirect) {
            let target
            if (redirect) {
              if (redirect.substring(0, 1) === '/') target = new URL(window.location.origin + redirect)
              else target = new URL(redirect)
              window.location = target
            }
            if (messages && messages.length) {
              Alpine.store('messages').addBatch(messages)
            }
          },
          elementTransformer: {
            addValidationMessage: (form, field, messages) => {
              form.classList.add('error-form')
              appendErrors(field, messages)
            },
            removeFormValidations: (form) => {
              form.classList.remove('error-form')
              form
                .querySelectorAll('.field-error')
                .forEach((el) => el.classList.remove('field-error', 'border-red-600'))
              form
                .querySelectorAll('.error-message')
                .forEach((el) => el.remove())
            },
          },
        })
      },
    }
  })
})()

function appendErrors(field, messages) {
  let additionalClasses = []
  let wrapper = field.closest('.form-row')
  if (!wrapper) {
    wrapper = field.closest('form')
    additionalClasses = ['px-2.5', 'pt-2.5']
  }
  wrapper.classList.add('field-error', 'w-full', 'border-red-600')
  ;[...messages].forEach((message) => {
    const errorEl = document.createElement('div')
    errorEl.classList.add('error-message', 'text-red-600', 'w-full', 'order-1')
    if (additionalClasses.length) errorEl.classList.add(...additionalClasses)
    errorEl.innerText = message
    wrapper.append(errorEl)
  })
}