import Alpine from 'alpinejs'

export default (function () {
  Alpine.data('toggle', function (open = false) {
    return {
      open,
      toggle: {
        ['@click.prevent']() { this.open = !this.open },
      },
      content: {
        ['x-ref']: 'content',
      },
    }
  })
})()