import Alpine from 'alpinejs'

export default (function () {
  Alpine.data('measureGroup', function () {
    return {
      measurements: [],
      init() {
        const el = this.$root
        const targets = el.querySelectorAll('[data-measure-target]')
        const measure = () => {
          targets.forEach((el, i) => {
            this.measurements[i] = el.offsetHeight + 'px'
          })
        }

        measure()
        try {
          const obs = new ResizeObserver(measure)
          obs.observe(el)
        } catch (error) {
          // no ResizeObserver
        }
      },
    }
  })
})()