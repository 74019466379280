import Alpine from 'alpinejs'
import intersect from '@alpinejs/intersect'

Alpine.store('messages', {
  store: [],
  addBatch(messages) {
    this.store = [...this.store, ...messages]
  },
  add(message) {
    this.store = [...this.store, message]
  },
  remove(index) {
    this.store = [...this.store.slice(0, index), ...this.store.slice(index + 1)]
  },
})

import 'modules/toggle'
import 'modules/slider'
import 'modules/measureGroup'
import 'modules/form'

Alpine.plugin(intersect)

window.Alpine = Alpine
Alpine.start()
